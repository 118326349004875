import { useState } from 'react'
import Link from 'next/link'
import Icon from '@/components/icon/icon'
import { Collapse } from 'react-collapse'
import { useSelector } from 'react-redux'
import SignInLink from '@/components/common/sign-in-link'
import nextConfig from 'next/config'
import styleConfig from '@/styles/style-config'
import { addForwardSlash, isEmptyObject } from '@/lib/helpers'
import { trans } from '@/lib/locale-helper'
import { getRegion, getRegionNumber } from '@/lib/region-helper'
import { Image } from '../image'
import { socialClickGTM, newsletterSignupGTM } from '@/lib/gtm'
import { isEmpty } from '@/lib/lodash'

const { publicRuntimeConfig } = nextConfig()

const country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

const Footer = ({ footer }) => {
  const { isAuthenticated } = useSelector((state) => state.userReducer)
  const [isOpened, setIsOpened] = useState({
    help: false,
    buyingonline: false,
    myaccount: false,
  })
  const footerLinks = footer?.menuItems[0]?.fields

  const toggleMenu = (e, key) => {
    const newIsOpened = {
      ...isOpened,
      [key]: !isOpened[key],
    }

    // Get the collapse component and then check if collapsed or not.
    // Loop through all the links, depending on whether component is collapsed or not (inverted collapsed value, so checking if false).
    // If the component is collapsed then set the tabIndex value to -1 (which means the link should not be navigated to using the tab key).
    // Else, set the tabIndex value to 0, meaning the element can be navigated to using the tab key.
    // console.log(e.currentTarget)
    const collapseComponent = e.currentTarget.parentElement.parentElement
    const collapsed = collapseComponent.childNodes[1].getAttribute('aria-hidden')
    const hiddenLinks = collapseComponent.querySelector('nav ul')

    if (collapsed == 'false') {
      for (let i = 0; i < hiddenLinks.childNodes.length; i++) {
        hiddenLinks.childNodes[i].querySelector('a').setAttribute('tabIndex', '-1')
      }
    } else {
      for (let i = 0; i < hiddenLinks.childNodes.length; i++) {
        hiddenLinks.childNodes[i].querySelector('a').setAttribute('tabIndex', '0')
      }
    }

    setIsOpened(newIsOpened)
  }

  const formatToggleKey = (key) => {
    return key.toLowerCase().split(' ').join('')
  }

  const handleClick = async (values, action) => {
    //GTM
    newsletterSignupGTM()
    //

    let formId = footer?.formIds?.desktop

    if (window.innerWidth <= 767) {
      formId = footer?.formIds?.mobile
    }

    try {
      if (window !== 'undefined') {
        let _klOnsite = window._klOnsite || []
        // _klOnsite.push(['openForm', 'Uq8juF'])
        _klOnsite.openForm(formId)
      }
    } catch (error) {
      console.log('signup form error', error)
    }
  }

  const handleSocialClick = (menu) => {
    socialClickGTM(menu.fields)

    if (typeof window !== 'undefined' && menu?.fields?.url) {
      //JG - commetning out the below as currently the clicking a social link will navigate to that website in addition to open a new tab
      // window.location.href = menu?.fields?.url
      window.open(menu.fields.url, menu.fields.title)
    }
  }

  const footerMenu_1 = footerLinks?.firstColumn
  const footerMenu_2 = footerLinks?.secondColumn
  const footerMenu_3 = footerLinks?.thirdColumn

  const oneTrustToggle = (event) => {
    event.preventDefault()
    OneTrust.ToggleInfoDisplay()
  }

  return (
    <>
      <footer
        id="main-footer"
        className="footer container-xl 2xs:mt-72 md:mt-52 lg:mt-92 xl:mt-110 relative"
      >
        <div className="footer-xl z-0"></div>
        <div className="hidden lg:block bg-gray-900">
          <Link href="/">
            <div className="logo flex flex-shrink-0 absolute footer-full-logo">
              <Image
                src={`${publicRuntimeConfig.basePath}/images/gorewear-logo.svg`}
                unoptimized={true}
                className="w-full"
                alt="Gore Wear"
              />
            </div>
          </Link>
          <div className="pad-block relative bg-transparent">
            <div className="flex justify-between mb-31">
              <div className="">
                <div className="absolute font-primary uppercase font-normal lg:text-smd lg:leading-16 xl:text-md xl:tracking-tight xl:leading-18 text-white footer-inverted-text">
                  <span className="">
                    {trans('commited-to', 'COMMITTED TO')} <i>{trans('all-capital', 'ALL')}</i>{' '}
                    {trans('conditions', 'CONDITIONS')}
                  </span>
                </div>
              </div>
              {footerMenu_1 && footerMenu_1.length > 0 && (
                <div className="footer-menu-1">
                  {footerMenu_1.map((fMenu, key) => {
                    if (fMenu && fMenu.fields) {
                      return (
                        <div key={key} className="mb-5">
                          <div className="flex items-center justify-between mb-4 py-3 border-b border-gray-800 md:py-0 md:border-none">
                            <h4 className="bg-gray-900 text-sm leading-18 font-normal font-primary uppercase text-white">
                              {fMenu.fields.title}
                            </h4>
                            <Icon
                              className={`cursor-pointer text-secondary fill-current md:hidden`}
                              viewBox={`0 0 12 12`}
                              size={12}
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              icon={
                                isOpened[formatToggleKey(fMenu.fields.entryTitle)]
                                  ? 'minus'
                                  : 'plus'
                              }
                            />
                          </div>
                          <Collapse isOpened={isOpened[formatToggleKey(fMenu.fields.entryTitle)]}>
                            <nav>
                              {typeof fMenu.fields.menuItems != 'undefined' && (
                                <ul
                                  className="bg-gray-900 space-y-4 xl:pr-5 font-normal leading-18 text-sm text-gray-400 font-primary"
                                  style={{ width: 'fit-content' }}
                                >
                                  {fMenu.fields.menuItems.map((item, itemKey) => {
                                    if (!isEmptyObject(item) && item.fields) {
                                      if (!isAuthenticated) {
                                        if (
                                          item?.fields?.url === '/account/orderhistory' ||
                                          item?.fields?.url === '/account/wishlist' ||
                                          item?.fields?.url === '/account/dashboard'
                                        ) {
                                          return (
                                            <li
                                              key={itemKey}
                                              data-index={itemKey}
                                              className="footer-menu-link"
                                            >
                                              <SignInLink
                                                text={item.fields.title}
                                                linkClass="lg:hover:underline lg:hover:text-white"
                                              />
                                            </li>
                                          )
                                        }
                                      }

                                      return (
                                        <li key={itemKey}>
                                          <Link
                                            href={addForwardSlash(item.fields.url)}
                                            className="lg:hover:underline lg:hover:text-white footer-menu-link"
                                          >
                                            {item.fields.title}
                                          </Link>
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              )}
                            </nav>
                          </Collapse>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
              {footerMenu_2 && footerMenu_2.length > 0 && (
                <div className="footer-menu-2">
                  {footerMenu_2.map((fMenu, key) => {
                    if (fMenu && fMenu.fields) {
                      return (
                        <div key={key} className="mb-5">
                          <div className="flex bg-gray-900 items-center justify-between mb-4 py-3 border-b border-gray-800 md:py-0 md:border-none">
                            <h4 className="text-sm leading-18 font-normal font-primary uppercase text-white">
                              {fMenu.fields.title}
                            </h4>
                            <Icon
                              className={`cursor-pointer text-secondary fill-current md:hidden`}
                              viewBox={`0 0 12 12`}
                              size={12}
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              icon={
                                isOpened[formatToggleKey(fMenu.fields.entryTitle)]
                                  ? 'minus'
                                  : 'plus'
                              }
                            />
                          </div>
                          <Collapse isOpened={isOpened[formatToggleKey(fMenu.fields.entryTitle)]}>
                            <nav>
                              {typeof fMenu.fields.menuItems != 'undefined' && (
                                <ul
                                  className="bg-gray-900 space-y-4 font-normal text-sm leading-18 text-gray-400 font-primary"
                                  style={{ width: 'fit-content' }}
                                >
                                  {fMenu.fields.menuItems.map((item, itemKey) => {
                                    if (!isEmptyObject(item)) {
                                      if (!isAuthenticated) {
                                        if (
                                          item?.fields?.url === '/account/orderhistory' ||
                                          item?.fields?.url === '/account/wishlist' ||
                                          item?.fields?.url === '/account/dashboard'
                                        ) {
                                          return (
                                            <li
                                              key={itemKey}
                                              data-index={itemKey}
                                              className="footer-menu-link"
                                            >
                                              <SignInLink
                                                text={item.fields.title}
                                                linkClass="lg:hover:underline lg:hover:text-white"
                                              />
                                            </li>
                                          )
                                        }
                                      }

                                      return (
                                        <li key={itemKey}>
                                          <Link
                                            href={addForwardSlash(item.fields.url)}
                                            className="lg:hover:underline lg:hover:text-white footer-menu-link"
                                          >
                                            {item.fields.title}
                                          </Link>
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              )}
                            </nav>
                          </Collapse>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
              {footerMenu_3 && footerMenu_3.length > 0 && (
                <div className="footer-menu-3">
                  {footerMenu_3.map((fMenu, key) => {
                    if (fMenu && fMenu.fields) {
                      return (
                        <div key={key} className="mb-5">
                          <div className="flex bg-gray-900 items-center justify-between mb-4 py-3 border-b border-gray-800 md:py-0 md:border-none">
                            <h4 className="text-sm leading-18 font-normal font-primary uppercase text-white">
                              {fMenu.fields.title}
                            </h4>
                            <Icon
                              className={`cursor-pointer text-secondary fill-current md:hidden`}
                              viewBox={`0 0 12 12`}
                              size={12}
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              icon={
                                isOpened[formatToggleKey(fMenu.fields.entryTitle)]
                                  ? 'minus'
                                  : 'plus'
                              }
                            />
                          </div>
                          <Collapse isOpened={isOpened[formatToggleKey(fMenu.fields.entryTitle)]}>
                            <nav>
                              {typeof fMenu.fields.menuItems != 'undefined' && (
                                <ul
                                  className="bg-gray-900 space-y-4 font-normal leading-18 text-sm text-gray-400 font-primary"
                                  style={{ width: 'fit-content' }}
                                >
                                  {fMenu.fields.menuItems.map((item, itemKey) => {
                                    if (!isEmptyObject(item)) {
                                      if (!isAuthenticated) {
                                        if (
                                          item?.fields?.url === '/account/orderhistory' ||
                                          item?.fields?.url === '/account/wishlist' ||
                                          item?.fields?.url === '/account/dashboard'
                                        ) {
                                          return (
                                            <li
                                              key={itemKey}
                                              data-index={itemKey}
                                              className="footer-menu-link"
                                            >
                                              <SignInLink
                                                text={item.fields.title}
                                                linkClass="lg:hover:underline lg:hover:text-white"
                                              />
                                            </li>
                                          )
                                        }
                                      }

                                      return (
                                        <li key={itemKey}>
                                          <Link
                                            href={addForwardSlash(item.fields.url)}
                                            className="lg:hover:underline lg:hover:text-white footer-menu-link"
                                          >
                                            {item.fields.title}
                                          </Link>
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              )}
                            </nav>
                          </Collapse>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
              <div className="footer-menu-4">
                <div className="footer-newsletter-section bg-gray-900">
                  <h4 className="mb-5 text-md leading-18 font-primary text-white xl:leading-20 xl:text-spg font-normal xl:tracking-tight">
                    {footer?.subscriptionTitle}
                  </h4>
                  <p className="text-white text-sm leading-18 font-normal mb-8r">
                    {trans(
                      'footer-newsletter',
                      'Stay up to date with our latest product resleases, sales, and more. Plus get 15% off your first order.'
                    )}
                  </p>
                  <button
                    className="btn border border-white capitalize text-smd w-full rounded-full bg-transparent text-white mb-30 lg:hover:bg-white lg:hover:text-black lg:focus:bg-white lg:focus:text-black"
                    onClick={handleClick}
                  >
                    {trans('sign-me-up', 'Sign Me Up')}
                  </button>
                  {footer && typeof footer?.socialLinks != 'undefined' && (
                    <>
                      <ul className="flex text-white space-x-30 mb-30">
                        {footer.socialLinks.fields.menuItems.map((menu, key) => (
                          <li key={key}>
                            <button onClick={() => handleSocialClick(menu)}>
                              <Icon
                                className={`cursor-pointer text-gray-600 fill-current lg:hover:opacity-50 lg:focus:opacity-50`}
                                viewBox={`0 0 40 40`}
                                size={40}
                                icon={`footer-${menu.fields.title.toLowerCase()}`}
                              />
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="flex justify-between">
                        <div className="inline-block bg-gray-900">
                          <a
                            href={`tel:${getRegionNumber()}`}
                            className="text-xsm leading-16r tracking-tightest text-white font-tertiary lg:hover:underline lg:hover:text-white"
                          >
                            {getRegionNumber()}
                          </a>
                          <p className="text-xsm leading-16r tracking-tightest text-gray-400 font-tertiary uppercase mb-2.5">
                            {footer.contactMessage}
                          </p>
                          <p className="text-xsm leading-16r tracking-tightest text-white uppercase font-tertiary">
                            <a
                              className="lg:hover:underline lg:hover:text-white lg:focus:underline lg:focus:text-white"
                              href={`mailto:${footer.emailAddress}`}
                            >
                              {footer.emailAddress}
                            </a>
                          </p>
                          <p className="text-xsm leading-16r tracking-tightest text-gray-400 font-tertiary uppercase">
                            {footer.emailMessage}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pad-footer">
            <div className="block">
              <div className="bg-gray-900 inline-block pr-20 pt-2 border-t border-gray-400 align-bottom relative footer-lic-link w-full">
                <div className="footer-lic-link-sub">
                  <div className="text-xs text-gray-400 block mb-2">{footer?.legalText}</div>
                  <nav className="footer__legal-links--desktop flex flex-col text-xsm md:text-sm text-gray-400 md:flex-row">
                    <div>
                      {footer &&
                        typeof footer.legalMenu != 'undefined' &&
                        footer.legalMenu.fields.menuItems.map((menu, key) => (
                          <span key={key}>
                            <Link
                              href={menu.fields.url}
                              className="lg:hover:underline lg:hover:text-white"
                            >
                              {menu.fields.title}
                            </Link>
                            {key < footer.legalMenu.fields.menuItems.length - 1 && (
                              <span className="mx-1">|</span>
                            )}
                          </span>
                        ))}
                      <span>
                        <span className="mx-1">|</span>
                        <a
                          href="#"
                          className="onetrust-infodisplay lg:hover:underline lg:hover:text-white"
                          onClick={oneTrustToggle}
                        >
                          {trans('cookie-settings', 'Cookie Settings')}
                        </a>
                      </span>
                    </div>
                  </nav>
                </div>
                <div className="gore-logo absolute right-0">
                  <Image
                    className={'w-full'}
                    unoptimized={true}
                    width={60}
                    height={25}
                    src={`${publicRuntimeConfig.basePath}/images/gore_logo_1color_white 1.png`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden block">
          <Link href="/">
            <div className="logo flex flex-shrink-0 absolute footer-full-logo">
              <Image
                unoptimized={true}
                src={`${publicRuntimeConfig.basePath}/images/gorewear-logo.svg`}
                className="w-full"
                alt="Gore Wear"
              />
            </div>
          </Link>
          <div className="bg-gray-900">
            <div className="footer-menu-items px-48 pt-90 md:pb-2 xxs:pb-85s relative">
              {footerMenu_1 &&
                footerMenu_1.length > 0 &&
                footerMenu_1.map((fMenu, key) => {
                  if (fMenu && fMenu.fields) {
                    return (
                      <div key={key} className="hide-bg-pattern">
                        <div className="flex items-center justify-between py-3 border-t border-gray-800 h-54">
                          <h3 className="2xs:text-spgs 2xs:leading-15 md:text-smd md:leading-18 font-primary uppercase text-white">
                            {fMenu.fields.title}
                          </h3>
                          {isOpened[formatToggleKey(fMenu.fields.entryTitle)] ? (
                            <Icon
                              className={`cursor-pointer text-white fill-current `}
                              viewBox={`0 0 12 12`}
                              size={12}
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              icon={'minus'}
                            />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              className=""
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 0.25C9.41421 0.25 9.75 0.585786 9.75 1V8.25H17C17.4142 8.25 17.75 8.58579 17.75 9C17.75 9.41421 17.4142 9.75 17 9.75H9.75V17C9.75 17.4142 9.41421 17.75 9 17.75C8.58579 17.75 8.25 17.4142 8.25 17V9.75H1C0.585786 9.75 0.25 9.41421 0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H8.25V1C8.25 0.585786 8.58579 0.25 9 0.25Z"
                                fill="white"
                              />
                            </svg>
                          )}
                        </div>
                        <Collapse isOpened={isOpened[formatToggleKey(fMenu.fields.entryTitle)]}>
                          <nav>
                            {typeof fMenu.fields.menuItems != 'undefined' && (
                              <ul className="space-y-4 font-light text-sm text-gray-300 font-primary mb-5 ">
                                {fMenu.fields.menuItems.map((item, itemKey) => {
                                  if (!isEmptyObject(item) && item.fields) {
                                    if (!isAuthenticated) {
                                      if (
                                        item?.fields?.url === '/account/orderhistory' ||
                                        item?.fields?.url === '/account/wishlist' ||
                                        item?.fields?.url === '/account/dashboard'
                                      ) {
                                        return (
                                          <li key={itemKey} data-index={itemKey}>
                                            <SignInLink
                                              text={item.fields.title}
                                              linkClass="lg:hover:underline lg:hover:text-white"
                                            />
                                          </li>
                                        )
                                      }
                                    }

                                    return (
                                      <li key={itemKey}>
                                        <Link
                                          href={addForwardSlash(item.fields.url)}
                                          className="lg:hover:underline lg:hover:text-white"
                                        >
                                          {item.fields.title}
                                        </Link>
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            )}
                          </nav>
                        </Collapse>
                      </div>
                    )
                  }
                })}
              {footerMenu_2 &&
                footerMenu_2.length > 0 &&
                footerMenu_2.map((fMenu, key) => {
                  if (fMenu && fMenu.fields) {
                    return (
                      <div key={key} className="hide-bg-pattern">
                        <div className="flex items-center justify-between py-3 border-t border-gray-800 h-54">
                          <h3 className="2xs:text-spgs 2xs:leading-15 md:text-smd md:leading-18 font-primary uppercase text-white">
                            {fMenu.fields.title}
                          </h3>
                          {isOpened[formatToggleKey(fMenu.fields.entryTitle)] ? (
                            <Icon
                              className={`cursor-pointer text-white fill-current `}
                              viewBox={`0 0 12 12`}
                              size={12}
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              icon={'minus'}
                            />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              className=""
                              fill="none"
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 0.25C9.41421 0.25 9.75 0.585786 9.75 1V8.25H17C17.4142 8.25 17.75 8.58579 17.75 9C17.75 9.41421 17.4142 9.75 17 9.75H9.75V17C9.75 17.4142 9.41421 17.75 9 17.75C8.58579 17.75 8.25 17.4142 8.25 17V9.75H1C0.585786 9.75 0.25 9.41421 0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H8.25V1C8.25 0.585786 8.58579 0.25 9 0.25Z"
                                fill="white"
                              />
                            </svg>
                          )}
                        </div>
                        <Collapse isOpened={isOpened[formatToggleKey(fMenu.fields.entryTitle)]}>
                          <nav>
                            {typeof fMenu.fields.menuItems != 'undefined' && (
                              <ul className="space-y-4 font-light text-sm text-gray-300 font-primary mb-5 ">
                                {fMenu.fields.menuItems.map((item, itemKey) => {
                                  if (!isEmptyObject(item)) {
                                    if (!isAuthenticated) {
                                      if (
                                        item?.fields?.url === '/account/orderhistory' ||
                                        item?.fields?.url === '/account/wishlist' ||
                                        item?.fields?.url === '/account/dashboard'
                                      ) {
                                        return (
                                          <li key={itemKey} data-index={itemKey}>
                                            <SignInLink
                                              text={item.fields.title}
                                              linkClass="lg:hover:underline lg:hover:text-white"
                                            />
                                          </li>
                                        )
                                      }
                                    }

                                    return (
                                      <li key={itemKey}>
                                        <Link
                                          href={addForwardSlash(item.fields.url)}
                                          className="lg:hover:underline lg:hover:text-white"
                                        >
                                          {item.fields.title}
                                        </Link>
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            )}
                          </nav>
                        </Collapse>
                      </div>
                    )
                  }
                })}
              {footerMenu_3 &&
                footerMenu_3.length > 0 &&
                footerMenu_3.map((fMenu, key) => {
                  if (fMenu && fMenu.fields) {
                    return (
                      <div key={key} className="hide-bg-pattern">
                        <div className="flex items-center justify-between py-3 border-t border-gray-800 h-54">
                          <h3 className="2xs:text-spgs 2xs:leading-15 md:text-smd md:leading-18 font-primary uppercase text-white">
                            {fMenu.fields.title}
                          </h3>
                          {isOpened[formatToggleKey(fMenu.fields.entryTitle)] ? (
                            <Icon
                              className={`cursor-pointer text-white fill-current `}
                              viewBox={`0 0 12 12`}
                              size={12}
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                              icon={'minus'}
                            />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              className=""
                              fill="none"
                              onClick={(e) =>
                                toggleMenu(e, formatToggleKey(fMenu.fields.entryTitle))
                              }
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 0.25C9.41421 0.25 9.75 0.585786 9.75 1V8.25H17C17.4142 8.25 17.75 8.58579 17.75 9C17.75 9.41421 17.4142 9.75 17 9.75H9.75V17C9.75 17.4142 9.41421 17.75 9 17.75C8.58579 17.75 8.25 17.4142 8.25 17V9.75H1C0.585786 9.75 0.25 9.41421 0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H8.25V1C8.25 0.585786 8.58579 0.25 9 0.25Z"
                                fill="white"
                              />
                            </svg>
                          )}
                        </div>
                        <Collapse isOpened={isOpened[formatToggleKey(fMenu.fields.entryTitle)]}>
                          <nav>
                            {typeof fMenu.fields.menuItems != 'undefined' && (
                              <ul className="space-y-4 font-light text-sm text-gray-300 font-primary mb-5 ">
                                {fMenu.fields.menuItems.map((item, itemKey) => {
                                  if (!isEmpty(item)) {
                                    if (!isAuthenticated) {
                                      if (
                                        item?.fields?.url === '/account/orderhistory' ||
                                        item?.fields?.url === '/account/wishlist' ||
                                        item?.fields?.url === '/account/dashboard'
                                      ) {
                                        return (
                                          <li key={itemKey} data-index={itemKey}>
                                            <SignInLink
                                              text={item.fields.title}
                                              linkClass="lg:hover:underline lg:hover:text-white"
                                            />
                                          </li>
                                        )
                                      }
                                    }

                                    return (
                                      <li key={itemKey}>
                                        <Link
                                          href={addForwardSlash(item.fields.url)}
                                          className="lg:hover:underline lg:hover:text-white"
                                        >
                                          {item.fields.title}
                                        </Link>
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            )}
                          </nav>
                        </Collapse>
                      </div>
                    )
                  }
                })}
              <div className="footer-newsletter-section border-t border-gray-800">
                <div className="relative hide-bg-pattern pt-31 bg-gray-900">
                  <h4 className="mb-4 2xs:text-17 2xs:leading-15 md:text-19 md:leading-17 font-normal font-primary text-white">
                    {footer?.subscriptionTitle}
                  </h4>
                  <p className="text-white md:leading-18 2xs:leading-17 2xs:text-xsm md:text-sm font-normal mb-2">
                    {trans(
                      'footer-newsletter',
                      'Stay up to date with our latest product resleases, sales, and more. Plus get 15% off your first order.'
                    )}
                  </p>
                  <button
                    className="btn h-56 justify-center items-center border border-white capitalize text-smd leading-18 font-normal w-full md:w-1/2 rounded-full bg-transparent text-white"
                    onClick={handleClick}
                  >
                    {trans('sign-me-up', 'Sign Me Up')}
                  </button>
                </div>
                <div className="flex">
                  <div className="w-full md:w-1/2 bg-gray-900">
                    {footer && typeof footer.socialLinks != 'undefined' && (
                      <>
                        <ul className="flex text-white space-x-30 mt-32 mb-30 relative ">
                          {footer.socialLinks.fields.menuItems.map((menu, key) => (
                            <li key={key}>
                              <button
                                onClick={() => handleSocialClick(menu)}
                                aria-label={`Find us on ${menu.fields.title}`}
                              >
                                <Icon
                                  className={`cursor-pointer text-gray-600 fill-current lg:hover:text-white`}
                                  viewBox={`0 0 40 40`}
                                  size={40}
                                  icon={`footer-${menu.fields.title.toLowerCase()}`}
                                />
                              </button>
                            </li>
                          ))}
                        </ul>
                        <div className="flex relative">
                          <div>
                            <a
                              href={`tel:${getRegionNumber()}`}
                              className="text-xsm leading-16r tracking-tightest text-white font-tertiary"
                            >
                              {getRegionNumber()}
                            </a>
                            <p className="text-xsm leading-16r tracking-tightest text-gray-400 font-tertiary uppercase mb-2.5">
                              {footer.contactMessage}
                            </p>
                            <p className="text-xsm leading-16r tracking-tightest text-white font-tertiary uppercase">
                              <a
                                className="lg:hover:underline lg:hover:text-white"
                                href={`mailto:${footer.emailAddress}`}
                              >
                                {footer.emailAddress}
                              </a>
                            </p>
                            <p className="text-xsm leading-16r tracking-tightest text-gray-400 font-tertiary uppercase">
                              {footer.emailMessage}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="inline-block md:pr-20 pt-2 border-t border-gray-400 align-bottom relative footer-lic-link mt-5 bg-gray-900">
                <div className="footer-lic-link-sub hide-bg-pattern">
                  <div className="text-xs text-gray-400 block mb-2">{footer?.legalText}</div>
                  <nav className="footer__legal-links--mobile flex flex-col text-xsm md:text-sm text-gray-400 md:flex-row">
                    <div>
                      {footer &&
                        typeof footer.legalMenu != 'undefined' &&
                        footer.legalMenu.fields.menuItems.map((menu, key) => (
                          <span key={key}>
                            <Link
                              href={menu.fields.url}
                              className="lg:hover:underline lg:hover:text-white"
                            >
                              {menu.fields.title}
                            </Link>
                            {key < footer.legalMenu.fields.menuItems.length - 1 && (
                              <span className="mx-1">|</span>
                            )}
                          </span>
                        ))}
                      <span>
                        <span className="mx-1">|</span>
                        <a href="#" className="onetrust-infodisplay" onClick={oneTrustToggle}>
                          {trans('cookie-settings', 'Cookie Settings')}
                        </a>
                      </span>
                    </div>
                  </nav>
                </div>
                <div className="2xs:mt-17 md:mt-0">
                  <div className="gore-logo">
                    <Image
                      unoptimized={true}
                      className={'w-full'}
                      width={60}
                      height={25}
                      src={`${publicRuntimeConfig.basePath}/images/gore_logo_1color_white 1.png`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <style jsx>
        {`
          div.gore-logo {
            width: 60px;
            height: 25px;
            top: 30px;
          }
          .hide-bg-pattern {
            background: #1c1c1c;
          }
          .footer-menu-link {
            width: fit-content;
            background-color: #1c1c1c;
          }
          .footer-xl {
            background-position: ;
          }
          .footer-inverted-text {
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            left: 7.8%;
            width: 350px;
          }

          @media (min-width: ${styleConfig.theme.screens.xxs}) {
            .footer-xl {
              background: url('${publicRuntimeConfig.basePath}/images/footer-2500.png');
              position: absolute;
              left: -120px;
              right: 0px;
              top: 0px;
              bottom: 0px;
              // background-position: center;
              pointer-events: none;
            }
          }
          @media (min-width: ${styleConfig.theme.screens.md}) {
            .footer-xl {
              background: url('${publicRuntimeConfig.basePath}/images/footer-2500.png');
              position: absolute;
              left: -120px;
              right: 0px;
              top: 0px;
              bottom: 0px;
              // background-position: center;
              pointer-events: none;
            }

            .footer__legal-links--mobile {
              padding-bottom: 100px;
            }
          }
          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .pad-block {
              padding-top: 90px;
              padding-left: 189px;
              padding-right: 49px;
            }
            .footer-menu-1 {
              width: 157px;
            }
            .footer-menu-2 {
              width: 157px;
            }
            .footer-menu-3 {
              width: 157px;
            }
            .footer-menu-4 {
              width: 310px;
            }
            .pad-footer {
              width: 100%;
              padding-left: 169px;
              padding-right: 49px;
            }
            .footer-xl {
              background-image: url('${publicRuntimeConfig.basePath}/images/footer-2500.png');
              position: absolute;
              left: -200px;
              right: 0px;
              top: 0px;
              bottom: 0px;
              background-position: center;
              pointer-events: none;
            }
            .footer-full-logo {
              right: 24px;
              top: -50px;
            }
            .footer-inverted-text {
              top: 214px;
            }

            .footer__legal-links--desktop {
              padding-bottom: 100px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .footer-xl {
              background-image: url('${publicRuntimeConfig.basePath}/images/footer-2500.png');
              position: absolute;
              left: -200px;
              right: 0px;
              top: 0px;
              bottom: 0px;
              // background-position: center;
              pointer-events: none;
            }
            .pad-footer {
              width: 100%;
              padding-left: 174px;
              padding-right: 200px;
            }
            .pad-block {
              padding-top: 90px;
              padding-left: 314px;
              padding-right: 200px;
            }
            .footer-menu-1 {
              width: 245px;
            }
            .footer-menu-2 {
              width: 245px;
              padding-left: 3%;
            }
            .footer-menu-3 {
              width: 245px;
              padding-left: 2%;
            }
            .footer-menu-4 {
              width: 412px;
              padding-left: 55px;
            }
            .footer-full-logo {
              right: 60px;
              top: -50px;
            }
            .footer-inverted-text {
              top: 232px;
            }
          }

          @media (max-width: ${styleConfig.theme.screens.lg}) {
            .footer-full-logo {
              right: 24px;
              top: -30px;
              width: 185px;
              height: 66px;
            }
          }
          ul,
          ol {
            list-style: none;
          }
        `}
      </style>
    </>
  )
}

export default Footer
